import "../styles/Project.css";

import Hero from "../components/project/Hero";
import Navbar from "../components/Navbar";
import SideTitle from "../components/project/SideTitle";
import Card from "../components/project/Card";
import Gallery from "../components/project/Gallery";
import OverrideBoundary from "../components/project/OverrideBoundary";
import Subpages from "../components/project/Subpages";
import Footer from "../components/project/Footer";

import image from "../images/Beam.svg";
import image2 from "../images/Glimpse.svg";
import imageblur from "../images/Glimpse-iphone-blur.png";
import userflows from "../images/mp-userflows.svg";

import uresearch1 from "../images/uresearch1.png";
import uresearch2 from "../images/uresearch2.png";

import workshop1 from "../images/workshop1.png";
import workshop2 from "../images/workshop2.png";

import breadboard from "../images/breadboard.png";
import ideation1 from "../images/ideation1.png";
import ideation2 from "../images/ideation2.png";
import responsiveness from "../images/responsiveness.png";
import documentation from "../images/documentation.png";

import Highlight from "../components/Highlight";
import Bold from "../components/Bold";
import List from "../components/project/List";
import ListText from "../components/project/ListText";
import Container from "../components/project/Container";
import Content from "../components/project/Content";
import Text from "../components/project/Text";
import Image from "../components/project/Image";
import Header from "../components/project/Header";
import Subpage from "../components/project/Subpage";
import CardSection from "../components/project/CardSection";
import CardHeader from "../components/project/CardHeader";
import CardText from "../components/project/CardText";
import { useEffect } from "react";

function Beam() {
  let barColor = "#313838";
  let highlightColor = "rgba(49, 56, 56, 0.4)";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar />
      <Hero
        title="Beam"
        description="A design system aimed to align all Smile CDR health products"
        image={image}
      />
      <Content>
        <SideTitle title="Project Context" barColor={barColor}>
          <Card>
            <CardSection>
              <CardHeader>ROLE</CardHeader>
              <CardText>Product Designer</CardText>
            </CardSection>
            <CardSection>
              <CardHeader>METHODS & TOOLS</CardHeader>
              <CardText>Figma, Design system, Design tools,</CardText>
              <CardText>Product thinking, Interaction design,</CardText>
              <CardText>Visual design</CardText>
            </CardSection>
            <CardSection>
              <CardHeader>DATE</CardHeader>
              <CardText>Jul 2023 - Nov 2023</CardText>
            </CardSection>
            <CardSection>
              <CardHeader>COLLABORATION</CardHeader>
              <CardText>UX Lead, Senior Designers</CardText>
            </CardSection>
          </Card>
          <Text>
            The beam framework library was a project created by Smile to
            re-brand the current white label products to establish a brand
            foundation that is clean, trustworthy and consistent. Beam was
            coined based off a synonym of smile.
          </Text>
        </SideTitle>

        <SideTitle title="Challenges" barColor={barColor}>
          <List title="Understanding the current problems">
            <ListText>Lack of consistency in design elements</ListText>
            <ListText>
              Difficulty re-branding products due to
              <ListText>Maturity of digital health products</ListText>
              <ListText>
                Complexity of replacing components (components structure must
                resemble existing code with only room for slight modifications)
              </ListText>
              <ListText>
                Different variants of plugins to build components (ex. Angular
                and Bootstrap) and white label products were using different
                design libraries.
              </ListText>
            </ListText>
          </List>

          <List title="Addressing these challenges as a team">
            <ListText>
              Ensuring the design system was not creating new work for
              developers but altering existing work
            </ListText>
            <ListText>
              Ensure a similar thematic and design for all components
            </ListText>
            <ListText>
              Newly created components would have a similar code strucutre and
              design to existing components to be replaced and optimized
            </ListText>
            <ListText>
              Create documentation that would educate devleopers to use the same
              library and development tools
            </ListText>
          </List>
        </SideTitle>

        <SideTitle title="Solution" barColor={barColor}>
          <List
            title="What factors should be considered when approaching the creation of
            the Beam component library?"
          >
            <ListText>Consistent design language</ListText>
            <ListText>Accessibility</ListText>
            <ListText>Modularity</ListText>
            <ListText>Documentation</ListText>
          </List>
          <List title="Process">
            <ListText>
              Discover which components were used the most across white label
              products
            </ListText>
            <ListText>
              Design alignment on existing products, discover which components
              are used a lot, run feedback sessions
            </ListText>
            <ListText>
              Create documentation for developers and designers on how to use
              components
            </ListText>
            <ListText>
              Outline use cases and create a component list of most impactful
              and easiest to change
            </ListText>
            <ListText>
              Create tickets and estimate timeline from design to development
            </ListText>
          </List>

          <List title="Results">
            <ListText>
              With the implementation of the component library
            </ListText>
            <ListText>
              Saw a positive feedback from developers and users (20% user
              satisifcation) of the new implemented components
            </ListText>
            <ListText>
              Design consistency across all white-label products
            </ListText>
            <ListText>
              Design turnaround was reduce greatly by 40% with faster
              development times
            </ListText>
          </List>

          <Header>Impact of the Beam Framework</Header>
          <Text>
            With a more cohesive brand identity, the library will help save on
            time and resources in the future as PO’s, developers and designers
            will have the necessary designs and documentation to work with.
          </Text>
        </SideTitle>

        <SideTitle title="Conclusions" barColor={barColor}>
          <List title="Key Takeaways">
            <ListText>How to effective create a design system</ListText>
            <ListText>
              Consistently updating and expanding hte library to manage
              nonrefundable products
            </ListText>
            <ListText>
              Collaborating and creating readable and straightforward
              documentation for developers and the contrast in design vs
              development language
            </ListText>
          </List>
          <Text>
            Overall, the Beam Framework Library was a great initiative in
            creating design consistency across all Smile CDR products. In terms
            of improvements, there can be systematic improvements in developing
            the library such as progress tracking for component development
            Furthermore, a feedback system to outline changes and facilitate
            discussion would be a nice-to-have given that much of the feedback
            was resolved through continuous meetings.
          </Text>
        </SideTitle>
      </Content>
      <Footer
        image={imageblur}
        title="Glimpse Social"
        subtext="Mobile Application, Branding"
        path="/projects/glimpsesocial"
      />
    </>
  );
}

export default Beam;
