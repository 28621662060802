import { motion } from "framer-motion";

interface SubheaderProps {
  children: React.ReactNode;
}

function SubHeader(props: SubheaderProps) {
  let { children } = props;
  return (
    <motion.h2
      initial={{ y: 100 }}
      whileInView={{ y: 0 }}
      transition={{ duration: 0.4 }}
      viewport={{ once: true }}
    >
      {children}
    </motion.h2>
  );
}

export default SubHeader;
