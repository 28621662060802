import { motion } from "framer-motion";

interface GalleryProps {
  images: string[];
  titles?: string[];
  captions?: string[];
  caption?: string;
  alt?: boolean;
  backgroundColor?: string;
}

function Gallery(props: GalleryProps) {
  let {
    images,
    caption = "",
    titles,
    captions,
    alt = false,
    backgroundColor,
  } = props;
  let classes = "gallery";
  if (alt) {
    classes += " alt";
  }
  return (
    <motion.div
      className={classes}
      initial={{ y: 100 }}
      whileInView={{ y: 0 }}
      transition={{ duration: 0.4 }}
      viewport={{ once: true }}
      style={backgroundColor ? { backgroundColor: backgroundColor } : {}}
    >
      <div className="images">
        {images.map((image, index) => {
          return (
            <div className="image">
              {titles && titles[index] ? <h1>{titles[index]}</h1> : ""}
              <img src={image} alt="Gallery" />
              {captions && captions[index] ? <p>{captions[index]}</p> : ""}
            </div>
          );
        })}
      </div>
      <p className="caption">{caption}</p>
    </motion.div>
  );
}

export default Gallery;
