const InternalNavigate = (path: string) => {
  let a = document.createElement("a");
  a.href = path;
  a.click();
};

const ExternalNavigateNewPage = (path: string) => {
  let a = document.createElement("a");
  a.href = path;
  a.target = "_blank";
  a.click();
};

const ExternalNavigateOnPage = (path: string) => {
  window.location.href = path;
};

export { InternalNavigate, ExternalNavigateOnPage, ExternalNavigateNewPage };
