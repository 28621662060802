interface HighlightProps {
  children: React.ReactNode;
  highlightColor?: string;
}

function Highlight(props: HighlightProps) {
  let { children, highlightColor } = props;

  return (
    <span
      className="highlight"
      style={
        highlightColor ? { borderBottom: "4px solid " + highlightColor } : {}
      }
    >
      {children}
    </span>
  );
}

export default Highlight;
