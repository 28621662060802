import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "./styles/App.css";
import Work from "./pages/Work";
import About from "./pages/About";
import Marketplace from "./projects/Marketplace";
import Glimpsesocial from "./projects/GlimpseSocial";
import Beam from "./projects/Beam";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Work />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/projects/marketplace",
    element: <Marketplace />,
  },
  {
    path: "/projects/glimpsesocial",
    element: <Glimpsesocial />,
  },
  {
    path: "/projects/beam",
    element: <Beam />,
  },
]);

function App() {
  return (
    <>
      <div className="page">
        <RouterProvider router={router} />
      </div>
    </>
  );
}

export default App;
