import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import Typewriter from "typewriter-effect";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

import Glimpse from "../images/Glimpse-Thumbnail.svg";
import Beam from "../images/Beam-Thumbnail.svg";
import Marketplace from "../images/Marketplace-Thumbnail.svg";

import "../styles/Work.css";
import LinkText from "../components/Link";
import {
  ExternalNavigateNewPage,
  ExternalNavigateOnPage,
  InternalNavigate,
} from "../Functions";

interface HeroProps {
  texts?: string[];
}

interface TagProps {
  text: string;
  action: () => void;
}

interface ProjectProps {
  image: string;
  title: string;
  path: string;
  tags?: (TagProps | string)[];
}

function Hero(props: HeroProps) {
  let { texts } = props;
  return (
    <motion.div
      className="workHero"
      initial={{ x: -100 }}
      animate={{
        x: 0,
      }}
      transition={{ duration: 1 }}
      viewport={{ once: true }}
    >
      <h1 className="subtext">
        👋 Hello, I'm{" "}
        <LinkText action={() => InternalNavigate("/about")}>
          <Link to="/downloads/Johnny_HN_Resume.pdf" target="_blank">
            Johnny Hoang Nguyen.
          </Link>
        </LinkText>
      </h1>
      <div className="header">
        <h1>I create better experiences for </h1>
        <Typewriter
          options={{
            strings: texts,
            autoStart: true,
            loop: true,
            deleteSpeed: 40,
            delay: 100,
            wrapperClassName: "typewriterText",
            cursorClassName: "typewriterText",
          }}
        />
      </div>
    </motion.div>
  );
}

function Project(props: ProjectProps) {
  let { image, title, tags, path } = props;
  let navigate = useNavigate();

  if (!tags) {
    tags = [];
  }

  return (
    <div className="project pressable" onClick={() => navigate(path)}>
      <img src={image} className="thumbnail" alt={title + " Thumbnail"} />
      <div className="details">
        <h1 className="title">{title}</h1>
        <div className="tags">
          {tags.map((tag) => {
            let classes = "tag";
            let text = typeof tag === "object" ? tag.text : tag;

            if (typeof tag === "object") {
              classes += " pressable";
            }

            return <div className={classes}>{text}</div>;
          })}
        </div>
      </div>
    </div>
  );
}

function Work() {
  let heroTexts = ["users", "people"];

  return (
    <>
      <Navbar />
      <div className="verticalAlign">
        <Hero texts={heroTexts} />
        <div className="projects">
          <Project
            image={Marketplace}
            title="Marketplace"
            path="/projects/marketplace"
            tags={["Branding", "Desktop App", "Mobile App"]}
          />
          <Project
            image={Glimpse}
            title="Glimpse Social"
            path="/projects/glimpsesocial"
            tags={["Branding", "Mobile App"]}
          />
        </div>
        <Project
          image={Beam}
          title="Beam"
          path="/projects/beam"
          tags={["Style Framework", "Interaction Design"]}
        />

        {/* <Project
          image={defaultImage}
          title="Glimpse Social"
          path="/"
          tags={["Design", "Software", { text: "Twitter", action: () => {} }]}  <- button link
        /> */}
      </div>
      <Footer />
    </>
  );
}

export default Work;
