import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

interface FooterProps {
  image: string;
  title: string;
  subtext: string;
  path: string;
}

function Footer(props: FooterProps) {
  let { image, title, path, subtext } = props;
  const navigate = useNavigate();

  return (
    <motion.div
      initial={{ y: 100 }}
      whileInView={{ y: 0 }}
      transition={{ duration: 0.4 }}
      className="projectFooter"
      onClick={() => {
        navigate(path);
      }}
      viewport={{ once: true }}
    >
      <div className="text">
        <div className="texts">
          <h1 className="next">Next</h1>
          <div className="details">
            <h1>{title}</h1>
            <p>{subtext}</p>
          </div>
        </div>
      </div>
      <div className="image" style={{ backgroundImage: `url(${image})` }} />
    </motion.div>
  );
}

export default Footer;
