import { motion } from "framer-motion";

interface CardProps {
  children: React.ReactNode;
}

function Card(props: CardProps) {
  let { children } = props;
  return (
    <motion.div
      className="card"
      initial={{ y: 100 }}
      whileInView={{ y: 0 }}
      transition={{ duration: 0.4 }}
      viewport={{ once: true }}
    >
      {children}
    </motion.div>
  );
}

export default Card;
