import { motion } from "framer-motion";

interface ListTextProps {
  children: React.ReactNode;
}

function ListText(props: ListTextProps) {
  let { children } = props;
  return (
    <motion.li
      initial={{ y: 100 }}
      whileInView={{ y: 0 }}
      transition={{ duration: 0.4 }}
      viewport={{ once: true }}
      className="listtext"
    >
      {children}
    </motion.li>
  );
}

export default ListText;
