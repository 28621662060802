import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

import "../styles/About.css";
import profilePortrait from "../images/aboutpic.png";

import {
  ExternalNavigateNewPage,
  InternalNavigate,
  ExternalNavigateOnPage,
} from "./../Functions";
import { Link } from "react-router-dom";
import LinkText from "../components/Link";

interface AboutMainProps {
  children?: React.ReactNode;
  title?: string;
  photo: string;
}

function AboutMain(props: AboutMainProps) {
  let { photo, children, title } = props;

  return (
    <div className="about">
      <h1>{title}</h1>
      <div className="info">
        <div className="content">
          {children}
          <div className="buttons">
            <div className="button">
              <Link to="/downloads/Johnny_HN_Resume.pdf" target="_blank">
                Resume
              </Link>
            </div>
          </div>
        </div>
        <img src={photo} alt="Portrait" />
      </div>
    </div>
  );
}

function About() {
  return (
    <>
      <Navbar />
      <AboutMain photo={profilePortrait} title={"Hey There!"}>
        <p>
          Hello! I am a product designer who enjoys creating digital experiences
          for people. I worked at Smile CDR Inc, working on the development of{" "}
          <LinkText
            action={() => ExternalNavigateOnPage("/projects/marketplace")}
          >
            Marketplace{" "}
          </LinkText>{" "}
          and their style framework. Previously, I designed and launched{" "}
          <LinkText
            action={() => ExternalNavigateOnPage("/projects/glimpsesocial")}
          >
            Glimpse Social
          </LinkText>{" "}
          as the founding designer.
        </p>
        <p>
          I received a Bachelor's degree from Business Administration and
          Computer Science (BBA & BS) at Wilfrid Laurier University. During my
          time at school, I found my passion for product design. Exploring the
          creativity and problem-solving of UX, I enjoyed taking ideas and
          transforming them into tangible, user-centric solutions.
        </p>
        <p>
          In my off time, I run an Etsy business selling digital assets with
          over 1700 sales. Moreover, I also love streaming music, rock climbing
          and online gaming.
        </p>
      </AboutMain>
      <Footer />
    </>
  );
}

export default About;
