import React from "react";
import { IoIosMail, IoLogoLinkedin } from "react-icons/io";
import { ExternalNavigateNewPage } from "../Functions";

function Footer() {
  return (
    <div className="footer">
      <div className="icons">
        <IoIosMail
          size={22}
          className="icon"
          onClick={() => {
            let a = document.createElement("a");
            a.href = "mailto: johnnyhnux@gmail.com";
            a.click();
          }}
        />
        <IoLogoLinkedin
          size={22}
          className="icon"
          onClick={() => {
            ExternalNavigateNewPage("https://www.linkedin.com/in/johnny-hn/");
          }}
        />
      </div>
      <h1 className="trademark">
        @ 2023 All rights reserved by Johnny Hoang Nguyen
      </h1>
    </div>
  );
}

export default Footer;
