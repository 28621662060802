interface SubpageProps {
  children: React.ReactNode;
}

function Subpage(props: SubpageProps) {
  let { children } = props;
  return <div className="subpage">{children}</div>;
}

export default Subpage;
