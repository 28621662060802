interface CardSectionProps {
  children: React.ReactNode;
}

function CardSection(props: CardSectionProps) {
  let { children } = props;
  return <div className="section">{children}</div>;
}

export default CardSection;
