interface LinkProps {
  children: React.ReactNode;
  action: () => void;
}

function LinkText(props: LinkProps) {
  let { children, action } = props;
  return (
    <span className="link" onClick={action}>
      {children}
    </span>
  );
}

export default LinkText;
