interface CardHeaderProps {
  children: React.ReactNode;
}

function CardHeader(props: CardHeaderProps) {
  let { children } = props;
  return <h1 className="header">{children}</h1>;
}

export default CardHeader;
