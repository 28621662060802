import { motion } from "framer-motion";
import Header from "./Header";

interface ListProps {
  children: React.ReactNode;
  title: string;
}

function List(props: ListProps) {
  let { children, title } = props;

  return (
    <motion.ul
      className="list"
      initial={{ y: 100 }}
      whileInView={{ y: 0 }}
      transition={{ duration: 0.4 }}
      viewport={{ once: true }}
    >
      <Header>{title}</Header>
      {children}
    </motion.ul>
  );
}

export default List;
