import { motion } from "framer-motion";

interface Props {
  title: string;
  children?: React.ReactNode;
  barColor?: string;
}

function SideTitle(props: Props) {
  let { children, title, barColor } = props;
  return (
    <motion.div
      className="sideTitle"
      initial={{ y: 100 }}
      whileInView={{ y: 0 }}
      transition={{ duration: 0.4 }}
      viewport={{ once: true }}
    >
      <div className="left">
        <div
          className="bar"
          style={barColor ? { backgroundColor: barColor } : {}}
        />
        <h1>{title}</h1>
      </div>
      <div className="content">{children ? children : ""}</div>
    </motion.div>
  );
}

export default SideTitle;
