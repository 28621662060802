interface CardTextProps {
  children: React.ReactNode;
}

function CardText(props: CardTextProps) {
  let { children } = props;
  return <p className="text">{children}</p>;
}

export default CardText;
